export const DEFAULT_SIZE_PAGE = 5;
export const DEFAULT_PAGE = 1;

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  TITLE: PROPERTY_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredPaymentsByText(payments, sorting) {
  return payments?.filter(
    (payment) =>
      FILTER_PROPERTIES.filter((property) =>
        payment[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

const PAYMENT_TYPE_CASH = 'CASH';
const PAYMENT_TYPE_CREDIT_CARD = 'CREDIT_CARD';
const PAYMENT_TYPE_DEBIT_CARD = 'DEBIT_CARD';
const PAYMENT_TYPE_THIRD_PARTY = 'THIRD_PARTY';

export const paymentTypes = [
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.cash', value: PAYMENT_TYPE_CASH },
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.creditCard', value: PAYMENT_TYPE_CREDIT_CARD },
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.debitCard', value: PAYMENT_TYPE_DEBIT_CARD },
  { label: 'restaurant:payments.formCreatePayment.paymentsTypesLabels.thirdParty', value: PAYMENT_TYPE_THIRD_PARTY },
];
