/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';

import { uniq, flatten } from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import Table from '@experimental-components/Table';
import EyeOnOutline from '@experimental-components/IconsComponents/EyeOnOutline';
import EyeOffOutline from '@experimental-components/IconsComponents/EyeOffOutline';
import IconMoreVertical from '@experimental-components/IconsComponents/MoreVerticalV2';

import { Checkbox } from '@design-system';
import IconButton from '@design-system/IconButton';

import PlaceholdersTable from 'components/PlaceholdersTable';
import DisplayMessage from 'components/DisplayMessageV2';
import ImageDropzoneProductList from 'components/ImageDropzoneProductList';
import ConfirmDialog from 'components/ConfirmDialog';
import ActionsMenuMaker from 'components/ActionsMenuMaker';

import { statusType } from 'utils/products';
import { getUnitWeight } from 'utils/products/soldByGrammage';
import { drawerActions, drawerEntities } from 'utils/drawerMenuMaker';

import useStyles from './styles';

function ProductsTable({
  products,
  categoriesByUuid,
  toggleProductStock,
  deleteProduct,
  isLoading,
  deleteProductError,
  deleteProductLoading,
  deleteProductFetched,
  loadingEnabledProduct,
  onClearNotification,
  loadingUpdateProductImage,
  onUpdateProductImage,
  isSearchFilter,
  isLoadingScrollPagination,
  openDrawer,
  onDuplicateProduct,
  onLoadProductAssociations,
  productAssociationsState,
  hasAssociation,
  resetLoadProductAssociaions,
  resetDeleteProduct,
  isCheckAll,
  setIsCheckAll,
  setIsIndeterminate,
  productSelectedRow,
  setProductSelectedRow,
  excludedProductsOrdersLocal,
  setExcludedProductsOrdersLocal,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('menuMaker');
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const isSelected = (uuidInvoice) => {
    return productSelectedRow?.indexOf(uuidInvoice) !== -1;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setIsCheckAll(true);
      const newSelectees = products?.map((n) => n?.uuid);
      setProductSelectedRow(newSelectees);
      setExcludedProductsOrdersLocal([]);
      return;
    }

    setProductSelectedRow([]);
    setIsCheckAll(false);
  };

  const handleChecked = (event, uuidSelect) => {
    const selectedIndex = productSelectedRow.indexOf(uuidSelect);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(productSelectedRow, uuidSelect);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(productSelectedRow.slice(1));
    } else if (selectedIndex === productSelectedRow.length - 1) {
      newSelected = newSelected.concat(productSelectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        productSelectedRow.slice(0, selectedIndex),
        productSelectedRow.slice(selectedIndex + 1),
      );
    }

    setProductSelectedRow(newSelected);

    let excludedProducts = [];

    if (!event.target.checked) {
      if (!excludedProductsOrdersLocal.includes(uuidSelect)) {
        excludedProducts = excludedProducts.concat(excludedProductsOrdersLocal, uuidSelect);
      }

      setExcludedProductsOrdersLocal(excludedProducts);
    }

    if (event.target.checked) {
      if (excludedProductsOrdersLocal.includes(uuidSelect)) {
        excludedProducts = excludedProductsOrdersLocal.filter((item) => uuidSelect !== item);
        setExcludedProductsOrdersLocal(excludedProducts);
      }
    }

    event.stopPropagation();
  };

  useEffect(() => {
    if (deleteProductFetched || deleteProductError) {
      setAnchorEl(null);
      setAnchorMenu(null);
      setSelectedProduct(null);
      resetLoadProductAssociaions();
      resetDeleteProduct();
    }
  }, [deleteProductFetched, deleteProductError]);

  useEffect(() => {
    if (anchorEl && deleteProductFetched) {
      setAnchorEl(null);
      setSelectedProduct(null);
    }
  }, [anchorEl, deleteProductFetched]);

  useEffect(() => {
    if (!hasAssociation && productAssociationsState.isFetched && !productAssociationsState.isError) {
      setAnchorEl(anchorMenu);
    }
    if (productAssociationsState.isFetched || productAssociationsState.isError) {
      setAnchorMenu(null);
    }
  }, [hasAssociation, productAssociationsState.isFetched, productAssociationsState.isError]);

  useEffect(() => {
    return () => {
      onClearNotification();
    };
  }, [onClearNotification]);

  const handleChange = (productUuid, availability) => {
    onClearNotification();
    setSelectedProduct(productUuid);
    let availableType = statusType.STATUS_AVAILABLE;
    if (availability === statusType.STATUS_AVAILABLE) {
      availableType = statusType.STATUS_UNAVAILABLE;
    }

    return toggleProductStock({
      productUuid,
      availability: availableType,
    });
  };

  const productsRowsFilter = useMemo(
    () => products?.filter((item) => !excludedProductsOrdersLocal?.includes(item?.uuid)),
    [excludedProductsOrdersLocal, products],
  );

  const indeterminateCheck = useMemo(() => {
    if (productSelectedRow?.length > 0 && productSelectedRow?.length < products?.length) {
      setIsIndeterminate(true);
      return true;
    }
    if (productSelectedRow?.length === products?.length) {
      if (productSelectedRow?.length > 0) {
        setIsCheckAll(true);
      }
      setIsIndeterminate(false);
      return false;
    }
    if (productSelectedRow?.length === 0 && productSelectedRow?.length < products?.length) {
      setIsCheckAll(false);

      setIsIndeterminate(false);
      return false;
    }
    setIsIndeterminate(false);
    return false;
  }, [products?.length, productSelectedRow?.length]);

  function handleDuplicate() {
    setAnchorMenu(null);
    onDuplicateProduct(selectedProduct);
    setSelectedProduct(null);
  }

  const handleConfirmDelete = () => {
    deleteProduct(selectedProduct);
  };

  function handleCancelDeleteMenu() {
    setSelectedProduct(null);
    setAnchorEl(null);
    setAnchorMenu(null);
    resetLoadProductAssociaions();
  }

  useEffect(() => {
    if (isCheckAll) {
      setProductSelectedRow(productsRowsFilter?.map((n) => n?.uuid));
    }
  }, [productsRowsFilter, isCheckAll]);

  function handleDelete() {
    const productFound = products.find((p) => p.uuid === selectedProduct);
    if (productFound.sku) {
      onLoadProductAssociations(productFound);
    } else {
      setAnchorMenu(null);
      setAnchorEl(anchorMenu);
    }
  }

  const handleClickProductRow = (product) => {
    const query = { action: drawerActions.EDIT, entity: drawerEntities.PRODUCT, uuid: product?.uuid };

    history.replace({ search: stringify(query) });
  };

  const handleOpenActions = (product) => (event) => {
    setAnchorMenu(event.currentTarget);
    setSelectedProduct(product.uuid);
  };

  function handleCloseActions() {
    setAnchorMenu(null);
    setSelectedProduct(null);
  }

  function handleUpdateProductImage(productUuid, image) {
    setSelectedProduct(productUuid);
    onUpdateProductImage(productUuid, image);
  }

  const handleMenuActionsEdit = () => {
    setAnchorMenu(null);
    handleClickProductRow({ uuid: selectedProduct });
  };

  function renderBody() {
    if (isLoading) {
      return <PlaceholdersTable placeholderCols={9} />;
    }

    return products?.map((product, index) => {
      const uniqueMenuNames = uniq(
        flatten(
          product?.categories?.map((category) =>
            categoriesByUuid[category?.category?.uuid]?.menus.map((menu) => menu.name),
          ),
        ),
      );

      const unitWeight = getUnitWeight({ serving: product?.serving, servingUnit: product?.servingUnit });
      const isItemSelected = isSelected(product?.uuid);

      return (
        <Table.Row key={product?.uuid}>
          <Table.Cell align="center" className={classes.rowCheckbox} isBorder={index !== 0}>
            <Checkbox checked={isItemSelected} onClick={(event) => handleChecked(event, product?.uuid)} />
          </Table.Cell>
          <Table.Cell align="center" className={classes.rowImage} isBorder={index !== 0}>
            {loadingUpdateProductImage && selectedProduct === product?.uuid ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              <ImageDropzoneProductList
                initialImageUrl={product?.image}
                onImageChange={handleUpdateProductImage}
                productUuid={product?.uuid}
              />
            )}
          </Table.Cell>

          <Table.Cell align="left" component="th" isBorder={index !== 0} scope="row" width={198}>
            <Link
              aria-label="edit-product-location"
              className={classes.linkCell}
              color="textPrimary"
              component="button"
              data-testid={product?.uuid}
              onClick={() => handleClickProductRow(product)}
              underline="none"
              variant="body2"
            >
              {product.name}
            </Link>
            {product?.sku && <span className={classes.itemSku}>{product?.sku}</span>}
          </Table.Cell>

          {!openDrawer && (
            <>
              <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
                {uniqueMenuNames?.length > 0 && (
                  <Table.BigCell.ExpandableList
                    list={uniqueMenuNames?.map((menuName) => (
                      <Table.BigCell.Option key={menuName}>{menuName}</Table.BigCell.Option>
                    ))}
                  />
                )}
              </Table.BigCell>

              <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
                {product?.categories?.length > 0 && (
                  <Table.BigCell.ExpandableList
                    list={product?.categories?.map(({ category }) => (
                      <Table.BigCell.Option key={category?.uuid}>{category?.name}</Table.BigCell.Option>
                    ))}
                  />
                )}
              </Table.BigCell>

              <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
                {product?.modifierGroups?.length > 0 && (
                  <Table.BigCell.ExpandableList
                    list={product?.modifierGroups?.map(({ modifierGroup }) => (
                      <Table.BigCell.Option key={modifierGroup?.uuid}>{modifierGroup?.name}</Table.BigCell.Option>
                    ))}
                  />
                )}
              </Table.BigCell>
            </>
          )}

          <Table.Cell align="center" isBorder={index !== 0}>
            {loadingEnabledProduct && selectedProduct === product.uuid ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              <IconButton
                aria-label="availability-product"
                onClick={() => handleChange(product.uuid, product.availability)}
              >
                {product.availability === statusType.STATUS_AVAILABLE ? <EyeOnOutline /> : <EyeOffOutline />}
              </IconButton>
            )}
          </Table.Cell>

          <Table.Cell align="left" isBorder={index !== 0}>
            <Box display="flex">
              <Box>${product?.price}</Box>
              {unitWeight && (
                <Box>
                  /{Number(product?.serving)} {unitWeight}
                </Box>
              )}
            </Box>
          </Table.Cell>

          <Table.Cell align="right" isBorder={index !== 0}>
            <IconButton aria-label="actions" onClick={handleOpenActions(product)}>
              <IconMoreVertical />
            </IconButton>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  if (!products?.length && isSearchFilter) {
    return (
      <DisplayMessage
        data-testid="productsSearchNoData"
        id="productsSearchNoData"
        maxWidth={450}
        message={t('common:messages.searchMessageNoResults')}
        sizeIcon={120}
        title={t('common:messages.searchTitleNoResults')}
      />
    );
  }

  if (!products?.length && !isLoading && !isSearchFilter) {
    return (
      <DisplayMessage
        data-testid="productsNoData"
        id="productsNoData"
        message={t('menuMaker:messages.noProducts')}
        sizeIcon={120}
      />
    );
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.Row key="menu-items-table-columns">
            <Table.HeadCell className={classes.rowCheckbox}>
              <Checkbox
                checked={isCheckAll}
                disable={false}
                indeterminate={indeterminateCheck}
                onChange={handleSelectAllClick}
              />
            </Table.HeadCell>
            <Table.HeadCell className={classes.rowImage} />
            <Table.HeadCell align="left">{t('menuMaker:productsTable.header.name')}</Table.HeadCell>
            {!openDrawer && (
              <>
                <Table.HeadCell align="left">{t('menuMaker:productsTable.header.menus')}</Table.HeadCell>
                <Table.HeadCell align="left">{t('menuMaker:productsTable.header.categories')}</Table.HeadCell>
                <Table.HeadCell align="left">{t('menuMaker:productsTable.header.modifiers')}</Table.HeadCell>
              </>
            )}
            <Table.HeadCell>{t('menuMaker:productsTable.header.isInStock')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:productsTable.header.price')}</Table.HeadCell>
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {renderBody()}
          {isLoadingScrollPagination && <PlaceholdersTable placeholderCols={8} />}
        </Table.Body>
      </Table>

      <ActionsMenuMaker
        anchorEl={anchorMenu}
        isLoadingActionDelete={productAssociationsState.isFetching}
        onClose={handleCloseActions}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
        onEdit={handleMenuActionsEdit}
        open={Boolean(anchorMenu)}
      />

      <ConfirmDialog
        anchorEl={anchorEl}
        data-testid="popperProductDelete"
        id="popperProductDelete"
        isDisabled={deleteProductLoading}
        labelAccept={t('common:buttons.delete')}
        labelCancel={t('common:buttons.cancel')}
        loading={deleteProductLoading}
        onAccept={handleConfirmDelete}
        onCancel={handleCancelDeleteMenu}
        open={Boolean(anchorEl) && selectedProduct}
        title={t('menuMaker:deleteProduct.popperDialog.title')}
      />
    </>
  );
}

ProductsTable.propTypes = {
  products: PropTypes.array,
  categoriesByUuid: PropTypes.object,
  toggleProductStock: PropTypes.func,
  deleteProduct: PropTypes.func,
  onClearNotification: PropTypes.func,
  isLoading: PropTypes.bool,
  deleteProductError: PropTypes.bool,
  deleteProductLoading: PropTypes.bool,
  deleteProductFetched: PropTypes.bool,
  loadingEnabledProduct: PropTypes.bool,
  loadingUpdateProductImage: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  isLoadingScrollPagination: PropTypes.bool,
  onUpdateProductImage: PropTypes.func,
  openDrawer: PropTypes.bool,
  onDuplicateProduct: PropTypes.func,
};

export default ProductsTable;
