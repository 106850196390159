import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectAuthProviderDomain = (state) => state.auth || initialState;

const selectIsAuthenticated = createSelector(selectAuthProviderDomain, (authState) => authState.authenticated);

const selectAuthFetching = createSelector(selectAuthProviderDomain, ({ user, isFetching, authenticated }) => {
  if (!user && isFetching) {
    return true;
  }

  if (!authenticated && isFetching) {
    return true;
  }

  return false;
});

const selectAuthUser = createSelector(selectAuthProviderDomain, (authState) => authState.user);
const selectAuthUserPermissions = createSelector(selectAuthProviderDomain, (authState) => authState.permissions);

const selectLicense = createSelector(selectAuthProviderDomain, (authState) => authState.licenseState.license);
const selectLicenseState = createSelector(selectAuthProviderDomain, ({ licenseState }) => {
  return {
    fetching: licenseState.fetching,
    fetched: licenseState.fetched,
    error: licenseState.error,
  };
});

const selectChangeStoreLoading = createSelector(selectAuthProviderDomain, ({ user, isFetching, licenseState }) => {
  if (!user && isFetching) {
    return true;
  }

  if (!Object.entries(licenseState.license) && licenseState.fetching) {
    return true;
  }

  if (isFetching || licenseState.fetching) {
    return true;
  }

  return false;
});

const selectStoreModules = createSelector(selectAuthProviderDomain, (authState) => authState.licenseState.modules);

export {
  selectAuthProviderDomain,
  selectAuthUser,
  selectIsAuthenticated,
  selectAuthFetching,
  selectAuthUserPermissions,
  selectLicense,
  selectLicenseState,
  selectChangeStoreLoading,
  selectStoreModules,
};
