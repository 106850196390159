import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleResume: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  boxChart: {
    width: '100%',
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
    boxShadow: '0px 8px 40px rgba(196, 208, 237, 0.15)',
    height: '100%',
    minHeight: theme.typography.pxToRem(500),

    padding: theme.spacing(4),
  },
  staticHeightChart: {
    maxHeight: theme.typography.pxToRem(500),
  },
  wrapperTittleChart: {
    display: 'flex',
    alignItems: 'center',
    gap: '13px',
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.darkGrey[5]}`,
  },
  titleBoxChart: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
  textHidden: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    maxWidth: theme.typography.pxToRem(200),
    [theme.breakpoints.between('md', 'md')]: {
      maxWidth: theme.typography.pxToRem(160),
    },
  },
  textLegendHidden: {
    width: '100%',
    overflow: 'hidden',
    maxWidth: 'calc(100% - 32px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: theme.typography.pxToRem(14),
  },
  boxLegendsPeriod: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      '& > :not(:first-child)': {
        marginLeft: 0,
        marginTop: theme.spacing(1),
      },
    },
  },
  summaryDescription: {
    marginBottom: theme.spacing(3),
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  optionAll: {
    paddingBlock: theme.spacing(1.5),
  },

  rootLegend: {
    width: theme.typography.pxToRem(275),
  },

  productDeleted: {
    color: theme.palette.darkGrey[15],
    textDecorationLine: 'line-through',
  },

  baseTootlip: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(11),
    maxWidth: 250,
    padding: '1.1rem 1rem',
    '& > span': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  textOptionItemFilters: {
    fontSize: 14,
  },

  wrapperSummaries: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(0,210px))',
    alignItems: 'end',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

export default useStyles;
