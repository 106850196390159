import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from './actions';

import {
  selectReports,
  selectReportsPagination,
  selectReportsFetching,
  selectReportsFetched,
  selectReportsError,
  selectReportsPaginationStatus,
  selectExportReportsStatus,
  selectReportsSessions,
  selectReportsCashSessionsDetails,
  selectReportsCashSessionsDetailsPaginationStatus,
  selectReportsCashSessionsDetailsPagination,
  selectCashSessionsDetailsExportReportStatus,
  selectReportsProductDetailsStatus,
  selectReportsProductDetails,
  selectPaginationProductDetailsState,
  selectPaginationStatusCashRegisterSessions,
  selectPaginationValuesCashRegisterSessions,
  selectReportsCashSessionsDetailsAll,
  selectReportsSortingStatus,
} from './selectors';

function useReports({ storeUuid, reportKey } = {}) {
  const dispatch = useDispatch();
  const reportsRef = useRef({});
  const reportsPaginationRef = useRef({});
  const reportsSessionsRef = useRef([]);
  const reportsSessionsDetailsRef = useRef({});
  const reportsCashSessionsDetailsPaginationRef = useRef({});
  const paginationCashRegisterSessionsRef = useRef({});

  reportsRef.current = useSelector((state) => selectReports(state, { storeUuid }));
  reportsPaginationRef.current = useSelector((state) => selectReportsPagination(state, { storeUuid }));
  reportsCashSessionsDetailsPaginationRef.current = useSelector((state) =>
    selectReportsCashSessionsDetailsPagination(state, { reportKey }),
  );

  paginationCashRegisterSessionsRef.current = useSelector((state) =>
    selectPaginationValuesCashRegisterSessions(state, { storeUuid }),
  );

  const reportsFetching = useSelector((state) => selectReportsFetching(state, { storeUuid }));
  const reportsFetched = useSelector((state) => selectReportsFetched(state, { storeUuid }));
  const reportsError = useSelector((state) => selectReportsError(state, { storeUuid }));
  const reportsPaginationStatus = useSelector((state) => selectReportsPaginationStatus(state, { storeUuid }));
  const exportReportsStatus = useSelector((state) => selectExportReportsStatus(state, { storeUuid }));

  const reportsProductDetailsStatus = useSelector((state) => selectReportsProductDetailsStatus(state, { storeUuid }));
  const reportsProductDetailsObject = useSelector((state) => selectReportsProductDetails(state, { storeUuid }));
  const paginationProductDetailsStatus = useSelector((state) =>
    selectPaginationProductDetailsState(state, { storeUuid }),
  );

  const reportsSessions = useSelector((state) => selectReportsSessions(state, { storeUuid }));

  const paginationStatusCashRegisterSessions = useSelector((state) =>
    selectPaginationStatusCashRegisterSessions(state, { storeUuid }),
  );
  const reportsCashSessionsDetails = useSelector((state) => selectReportsCashSessionsDetails(state, { reportKey }));
  const reportsCashSessionsDetailsPaginationStatus = useSelector((state) =>
    selectReportsCashSessionsDetailsPaginationStatus(state, { reportKey }),
  );
  const cashSessionsDetailsExportReportStatus = useSelector((state) =>
    selectCashSessionsDetailsExportReportStatus(state, { reportKey }),
  );

  const reportsCashSessionsDetailsAll = useSelector((state) =>
    selectReportsCashSessionsDetailsAll(state, { reportKey }),
  );

  const reportsSortingStatus = useSelector((state) => selectReportsSortingStatus(state, { storeUuid }));

  const [reportsState, setReportsState] = useState({
    fetching: false,
    fetched: false,
    error: null,
  });

  const [reportsPaginationState, setReportsPaginationState] = useState({});
  const [exportReportsState, setExportReportsState] = useState({});
  const [reportsSessionsStatus, setReportsSessionsStatus] = useState({});
  const [reportsCashSessionsDetailsStatus, setReportsCashSessionsDetailsStatus] = useState({});
  const [reportsCashSessionsDetailsPaginationState, setReportsCashSessionsDetailsPaginationState] = useState({});
  const [cashSessionsDetailsExportReportState, setCashSessionsDetailsExportReportState] = useState({});
  const [reportsProductDetailsState, setReportsProductDetailsState] = useState({});
  const [reportsProductDetails, setReportsProductDetails] = useState({});
  const [paginationProductDetailsState, setPaginationProductDetailsState] = useState({});
  const [paginationCashRegisterSessionsState, setPaginationCashRegisterSessionsState] = useState({});
  const [reportsSessionsDetailsAll, setReportsSessionsDetailsAll] = useState({});
  const [reportsCashSessionsDetailsAllStatus, setReportsCashSessionsDetailsAllStatus] = useState({});
  const [reportsSortingState, setReportsSortingState] = useState({});

  useEffect(() => {
    setReportsState({
      fetching: reportsFetching,
      fetched: reportsFetched,
      error: reportsError,
    });
  }, [reportsError, reportsFetched, reportsFetching]);

  useEffect(() => {
    setReportsState({
      fetching: reportsFetching,
      fetched: reportsFetched,
      error: reportsError,
    });
  }, [reportsError, reportsFetched, reportsFetching]);

  useEffect(() => {
    setReportsPaginationState({
      ...reportsPaginationStatus,
    });
  }, [reportsPaginationStatus]);

  useEffect(() => {
    setExportReportsState({
      ...exportReportsStatus,
    });
  }, [exportReportsStatus]);

  /**
   * status request to cash register sessions
   */
  useEffect(() => {
    setReportsSessionsStatus({
      ...reportsSessions,
    });

    reportsSessionsRef.current = reportsSessions?.sessions;
  }, [reportsSessions]);

  useEffect(() => {
    setPaginationCashRegisterSessionsState({
      ...paginationStatusCashRegisterSessions,
    });
  }, [paginationStatusCashRegisterSessions]);

  useEffect(() => {
    const isEmptyReport = Object.entries(reportsCashSessionsDetails?.report).length === 0;
    if (!isEmptyReport) {
      setReportsCashSessionsDetailsStatus({
        ...reportsCashSessionsDetails,
      });

      reportsSessionsDetailsRef.current = reportsCashSessionsDetails?.report;
    }
  }, [reportsCashSessionsDetails]);

  useEffect(() => {
    setReportsCashSessionsDetailsPaginationState({
      ...reportsCashSessionsDetailsPaginationStatus,
    });
  }, [reportsCashSessionsDetailsPaginationStatus]);

  useEffect(() => {
    setCashSessionsDetailsExportReportState({
      ...cashSessionsDetailsExportReportStatus,
    });
  }, [cashSessionsDetailsExportReportStatus]);

  useEffect(() => {
    setReportsProductDetailsState({
      ...reportsProductDetailsStatus,
    });
  }, [reportsProductDetailsStatus]);

  useEffect(() => {
    setReportsProductDetails({
      ...reportsProductDetailsObject,
    });
  }, [reportsProductDetailsObject]);

  useEffect(() => {
    setPaginationProductDetailsState({ ...paginationProductDetailsStatus });
  }, [paginationProductDetailsStatus]);

  useEffect(() => {
    setReportsCashSessionsDetailsAllStatus({
      ...reportsCashSessionsDetailsAll,
    });
    setReportsSessionsDetailsAll(reportsCashSessionsDetailsAll.details);
  }, [reportsCashSessionsDetailsAll]);

  useEffect(() => {
    setReportsSortingState({
      ...reportsSortingStatus,
    });
  }, [reportsSortingStatus]);

  return {
    loadReportsProductDetails: (values) => dispatch(actions.getReportsProductDetails(values)),
    paginateProductDetails: (values) => dispatch(actions.getPaginationProductDetails(values)),
    resetPaginationProductDetails: (key) => dispatch(actions.getPaginationProductDetails.reset(key)),
    cashSessionsDetailsExportReportState,
    exportReportsState,
    paginationCashRegisterSessionsState,
    paginationCashRegisterSessionsValues: { ...paginationCashRegisterSessionsRef.current },
    reports: reportsRef.current,
    reportsCashSessionsDetailsAllStatus,
    reportsCashSessionsDetailsPaginationState,
    reportsCashSessionsDetailsPaginationValues: { ...reportsCashSessionsDetailsPaginationRef.current },
    reportsCashSessionsDetailsStatus,
    reportSessionsDetails: reportsSessionsDetailsRef.current,
    reportsPaginationState,
    reportsPaginationValues: { ...reportsPaginationRef.current },
    reportsProductDetails,
    reportsProductDetailsState,
    paginationProductDetailsState,
    reportsSessions: reportsSessionsRef.current,
    reportsSessionsDetailsAll,
    reportsSessionsStatus,
    reportsSortingState,
    reportsState,
    resetReportsProductDetails: (key) => dispatch(actions.getReportsProductDetails.reset(key)),
  };
}

export default useReports;
