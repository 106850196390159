const mappingTaxProductsTypes = {
  90101800: 'Servicios de comida para llevar y a domicilio',
  90101700: 'Servicios de cafetería',
  90101600: 'Servicios de banquetes y catering',
  90101503: 'Servicio de comida rápida',
  90101501: 'Restaurantes',
  90101500: 'Establecimientos para comer y beber',
  50381700: 'Purés de frutas tropicales',
  50307500: 'Subproductos de frutas frescas',
  50202500: 'Jugos o concentrados de bayas frescas',
  50202400: 'Jugos o concentrados de cítricos frescos',
  50202307: 'Bebida de chocolate o malta u otros',
  50202306: 'Refrescos',
  50202305: 'Jugo fresco',
  50202301: 'Agua',
  50202300: 'Bebidas no alcohólicas',
  50202207: 'Cocteles',
  50202203: 'Vino',
  50202201: 'Cerveza',
  50202200: 'Bebidas alcohólicas',
  50201715: 'Té de frutas',
  50201712: 'Bebidas de té',
  50201708: 'Bebida de café',
  50201700: 'Café y té',
  50193200: 'Ensaladas preparadas',
  50193100: 'Sopas',
  50193000: 'Bebidas y Comidas Infantiles',
  50192900: 'Pasta o tallarines natural',
  50192800: 'Empanadas y quiches y pasteles',
  50192700: 'Platos combinados empaquetados',
  50192600: 'Acompañamientos preparados',
  50192500: 'Sándwiches y panecillos con relleno',
  50192400: 'Mermeladas y gelatinas y pastas de untar de nuez y dulce y conservas de fruta',
  50192303: 'Helado de sabor o helado o postre de helado o yogurt congelado',
  50192300: 'Postres y decoraciones de postres',
  50192100: 'Pasabocas y snacks',
  50191500: 'Sopas y estofados',
  50182000: 'Tartas y empanadas y pastas',
  50181901: 'Pan fresco',
  50181900: 'Pan y galletas y pastelitos dulces',
  50161800: 'Productos de confitería',
  50131800: 'Queso',
  50131700: 'Productos de leche y mantequilla',
  50131609: 'Huevos preparados',
  50121901: 'Pulpo en escabeche',
  50121900: 'Pescados y mariscos',
  50121600: 'Marisco fresco',
  50111514: 'Cerdo, mínimamente procesado sin aditivos',
  50112019: 'Carne especializada, procesado con aditivo',
  50111513: 'Carne, mínimamente procesada sin aditivos',
  50112023: 'Carne especializada mixta, procesada con aditivos',
};

const mappingTaxServingUnit = {
  H87: 'Trozo',
  KGM: 'kilogramo',
  LTR: 'Litro',
  XBX: 'Caja',
  AS: 'Variedad',
  DPC: 'Docenas',
  XUN: 'Unidad',
  E48: 'Unidad de servicio',
};

function generateTypes(mapping) {
  const typesList = [];
  const objectArray = Object.entries(mapping);

  objectArray.forEach(([key, value]) => {
    let keyValue = key;

    if (typeof keyValue !== 'string') {
      keyValue = toString(keyValue);
    }

    typesList.push({ key: keyValue, label: `${keyValue} - ${value}` });
  });

  return typesList;
}

export const taxProductKeys = generateTypes(mappingTaxProductsTypes);
export const taxServingUnitTypes = generateTypes(mappingTaxServingUnit);
